enum AliveList {
    TASKLIST = 'TaskList',
    TASKLOG = 'TaskLog',
    TASKNODE = 'Node'
}

enum UpdateAliveType {
    ADD = 'add',
    REMOVE = 'remove'
}

export {
    AliveList,
    UpdateAliveType
}

<template>
    <div class="main__container">
        <router-view v-slot="{ Component }">
            <keep-alive :include="keepAliveList">
                <component :is="Component" />
            </keep-alive>
        </router-view>
    </div>
</template>
<script lang="ts" setup>
import { useTabsStore } from '@/stores/tabs';
import { computed } from 'vue';

const tabsStore = useTabsStore();

const keepAliveList = computed(() => {
    return tabsStore.keepAliveList;
});
</script>
<style lang="scss" scoped>
.main__container {
    background-color: #fff;
    overflow: hidden;
    padding: 28px;
    border-radius: 8px;
    height: calc(100vh - 36px);
}
</style>
<style lang="scss">
.tabs__view {
    & .ant-tabs-tab {
        user-select: none;
        padding: 0 16px 0 0 !important;
        & .ant-dropdown-trigger {
            padding-left: 16px;
        }
    }
    & .ant-tabs-bar {
        margin: 0 0 8px 0;
    }
    & .ant-tabs-tab-active {
        font-weight: normal;
        border-bottom: 1px solid #f0f0f0 !important;
    }
}
</style>

import { ref } from "vue";
import { defineStore } from "pinia";
import { AliveList, UpdateAliveType } from "@/type/tabs";

export const useTabsStore = defineStore("tabs", () => {
    const keepAliveList = ref<any[]>([
        AliveList.TASKLIST,
        AliveList.TASKLOG,
        AliveList.TASKNODE,
    ]);
    const updateKeepAliveList = (
        action: UpdateAliveType,
        componentName: string
    ) => {
        if (action === UpdateAliveType.ADD) {
            if (!keepAliveList.value.includes(componentName)) {
                keepAliveList.value.push(componentName);
            }

            if (keepAliveList.value.length > 10) {
                keepAliveList.value.shift();
            }
        } else if (action === UpdateAliveType.REMOVE) {
            keepAliveList.value = keepAliveList.value.filter(
                (name) => name !== componentName
            );
        }
    };

    return {
        keepAliveList,
        updateKeepAliveList,
    };
});

<template>
    <div id="menu">
        <div class="menu-box">
            <div class="menu__logo">
                <img
                    class="menu__logo-icon"
                    src="@/assets/images/logo.svg"
                    alt="任务调度平台"
                />
            </div>
            <a-menu
                v-model:openKeys="openKeys"
                v-model:selectedKeys="selectedKeys"
                style="width: 200px"
                mode="inline"
                :items="items"
                :theme="light"
                @click="handleClick"
            ></a-menu>
        </div>
        <div class="loginout">
            <user-avatar
                v-if="userInfo && userInfo.base_info && userInfo.base_info.name"
                class="user-avatar"
                v-model="collapsed"
                :name="userInfo.base_info.name"
            />
        </div>
    </div>
</template>
<script lang="ts">
import { useRoute, useRouter } from "vue-router";
import aIcon from "@/components/aicon/aicon.vue";
import {
    defineComponent,
    ref,
    watch,
    VueElement,
    h,
    computed,
} from "vue";
import type { MenuProps, ItemType } from "ant-design-vue";
import { defineUserStore } from "@/stores/user";
import { defineTaskStore } from "@/stores/task";
import UserAvatar from "@/components/avatar-box/index.vue";
import TaskListIcon from "@/assets/icons/task-list.svg";
import TaskListIconActive from "@/assets/icons/task-list-active.svg";
import NodeIcon from "@/assets/icons/node.svg";
import NodeIconActive from "@/assets/icons/node-active.svg";
import SysIcon from "@/assets/icons/sys.svg";
import SysIconActive from "@/assets/icons/sys-active.svg";
import UserIcon from "@/assets/icons/user.svg";
import UserIconActive from "@/assets/icons/user-active.svg";
import { useTabsStore } from "@/stores/tabs";
import { AliveList, UpdateAliveType } from "@/type/tabs";

export default defineComponent({
    name: "layoutMenu",
    computed: {},
    components: {
        aIcon,
        UserAvatar,
    },
    props: {
        collapsed: {
            required: true,
            type: Boolean,
        },
    },
    setup() {
        const route = useRoute();
        const selectedKeys = ref<string[]>([]);
        const openKeys = ref<string[]>(["task-menu", "setting", "user"]);
        const light = ref<string>("light");
        function getItem(
            label: VueElement | string,
            key: string,
            icon?: any,
            children?: ItemType[],
            type?: "group"
        ): ItemType {
            return {
                key,
                icon,
                children,
                label,
                type,
            } as ItemType;
        }

        const items = computed(() => {
            return [
                getItem(
                    "任务管理",
                    "task-menu",
                    () =>
                        h("img", {
                            src:
                                routerInfo.value === AliveList.TASKLIST ||
                                routerInfo.value === AliveList.TASKLOG
                                    ? TaskListIconActive
                                    : TaskListIcon,
                        }),
                    [
                        getItem("定时任务", AliveList.TASKLIST),
                        getItem("任务日志", AliveList.TASKLOG),
                    ]
                ),
                getItem("任务节点", AliveList.TASKNODE, () =>
                    h("img", {
                        src:
                            routerInfo.value === AliveList.TASKNODE
                                ? NodeIconActive
                                : NodeIcon,
                    })
                ),
                getItem(
                    "用户管理",
                    "user",
                    h("img", {
                        src:
                            routerInfo.value === "userManager"
                                ? UserIconActive
                                : UserIcon,
                    }),
                    [
                        getItem("分组配置", "UserGroup"),
                        getItem("用户权限", "userManager"),
                    ]
                ),

                getItem(
                    "系统管理",
                    "setting",
                    () =>
                        h("img", {
                            src:
                                routerInfo.value === "Sys" ||
                                routerInfo.value === "SysLog"
                                    ? SysIconActive
                                    : SysIcon,
                        }),
                    [...isAdmin.value? [getItem("系统设置", "Sys")]: [], getItem("系统日志", "SysLog")]
                ),
            ];
        });
        const router = useRouter();
        const handleClick: MenuProps["onClick"] = (e) => {

            const list = [
                AliveList.TASKLIST,
                AliveList.TASKLOG,
                AliveList.TASKNODE,
                "userManager",
                "UserGroup",
                "Sys",
                "SysLog",
            ];

            if (list.indexOf(e.key as string) > -1) {
                router.replace({ name: `${e.key}` });
            }

            if([AliveList.TASKLIST, AliveList.TASKLOG,AliveList.TASKNODE].includes(routerInfo.value)) {
                useTabsStore().updateKeepAliveList(UpdateAliveType.ADD, routerInfo.value);
            }
            if(e.key === AliveList.TASKLIST) {
                defineTaskStore().removeTaskId();
            }
            if(e.key === AliveList.TASKLOG) {
                defineTaskStore().removeLogId();
            }
        };


        const routerInfo: any = computed(() => {
            return route.name;
        });
        const userStore = defineUserStore();
        const userInfo: any = computed(() => {
            return userStore.User;
        });
        const isAdmin: any = computed(() => {
            return !!userInfo.value?.base_info?.is_admin;
        });

        watch(routerInfo, (val: string) => {
            selectedKeys.value = [val];
        });
        const personInfoVisible = ref(false);

        return {
            selectedKeys,
            routerInfo,
            openKeys,
            items,
            handleClick,
            light,
            userInfo,
            personInfoVisible,
        };
    },
});
</script>
<style lang="scss">
:where(.css-dev-only-do-not-override-1hsjdkk).ant-layout .ant-layout-sider {
    background-color: #fff;
}
:where(.css-dev-only-do-not-override-1hsjdkk).ant-menu-light.ant-menu-inline
    .ant-menu-sub.ant-menu-inline {
    background-color: rgba(250, 250, 250, 1);
}
.menu__logo {
    display: flex;
    align-items: center;
    padding: 24px 5px 10px 5px;
    height: 48px;
    line-height: 48px;
    overflow: hidden;
    white-space: nowrap;
    box-sizing: content-box;
    & .menu__logo-icon {
        width: 100%;
        margin-right: 8px;
        img {
            display: block;
            width: 100%;
        }
    }
}
#menu {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    height: 100%;
    min-height: 700px;
}
.menu-box {

    height: 630px;
}
.loginout {
    margin-bottom: 32px;
    height: 36px;
}
.ant-menu-submenu-selected > .ant-menu-submenu-title .ant-menu-title-content,
.ant-menu-item-selected > .ant-menu-title-content {
    font-weight: bold;
    color: #2365ff;
}
#app .ant-menu-item-selected {
    background-color: #eaf1ff;
    position: relative;
    border-radius: 0;
    &::after {
        content: "";
        position: absolute;
        top: 0;
        right: 1px;
        height: 100%;
        width: 3px;
        background-color: #2365ff;
    }
}
#app .ant-menu-item-selected .ant-menu-title-content {
    color: #2365ff;
}
.ant-menu-title-content {
    font-weight: 500;
}
.ant-menu-item-only-child .ant-menu-title-content {
    font-weight: 400;
    padding-left: 10px !important;

}

.ant-menu-light.ant-menu-inline .ant-menu-item {
    margin: 10px 0;
}
#app .ant-menu .ant-menu-submenu-title .icon-active path {
    stroke: #1677ff;
}

#app .ant-menu-root >.ant-menu-item {
    padding-left: 28px !important;
}
#app {
    .ant-menu-inline .ant-menu-item {
        width: 100%;
    }
}
</style>
